import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { QueryClientProvider } from "@tanstack/react-query";
import { IonApp, setupIonicReact, isPlatform } from "@ionic/react";
import "@ionic/react/css/core.css";
import "@ionic/react/css/normalize.css";
import "@ionic/react/css/structure.css";
import "@ionic/react/css/typography.css";

import { AppInsightsContext } from "@microsoft/applicationinsights-react-js";

import { aiReactPlugin } from "utils/app-insights-telemetry";
import { LocalRoutes } from "constants/routes";
import { queryClient } from "utils/hooks/api-client";

import PageWrap from "components/molecule/page-wrap";
import ErrorPage from "components/page/error-page";
import ApplicationPage from "components/page/application-page";
import EducationPage from "components/page/education-page";
import LearningPathPage from "components/page/learning-path-page";
import IdentityDocumentPage from "components/page/identity-document-page";
import CheckoutPage from "components/page/checkout-page/";
import SuccessPage from "components/page/success-page/";
import CompletePage from "components/page/complete-page";
import PendingPage from "components/page/pending-page";
import { RegistrationProvider } from "utils/context/registration";
import { PaymentsTypeOptions } from "types/payments";

import config from "config";

import "./App.scss";

/**
 * Global config for the Ionic Interactions
 */
setupIonicReact({
  // Disable animations on desktop
  animated: !isPlatform("desktop"),
  mode: "md",
});

/**
 * Routing, page layouts and Provider declarations
 */

const ApplyPages = () => (
  <PageWrap>
    <RegistrationProvider>
      <Routes>
        <Route path={LocalRoutes.APPLICATION} element={<ApplicationPage />} />
        <Route path={LocalRoutes.APPLICATION_EDUCATION} element={<EducationPage />} />
        <Route path={LocalRoutes.APPLICATION_DEGREE_LEARNING_PATH} element={<LearningPathPage />} />
        <Route path={LocalRoutes.APPLICATION_IDENTITY} element={<IdentityDocumentPage />} />
        <Route
          path={LocalRoutes.TUITION_FEE_CHECKOUT}
          element={<CheckoutPage checkoutType={PaymentsTypeOptions.TuitionFee} />}
        />
        <Route
          path={LocalRoutes.APPLICATION_FEE_CHECKOUT}
          element={<CheckoutPage checkoutType={PaymentsTypeOptions.ApplicationFee} />}
        />
        <Route path={LocalRoutes.APPLICATION_PENDING} element={<PendingPage />} />
        <Route path={LocalRoutes.APPLICATION_SUCCESS} element={<SuccessPage />} />
        <Route path={LocalRoutes.APPLICATION_COMPLETE} element={<CompletePage />} />
        <Route path="*" element={<ErrorPage />} />
      </Routes>
    </RegistrationProvider>
  </PageWrap>
);

/**
 * GTM declaration
 */
const gtmService = (gtmID: string) => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({ "gtm.start": new Date().getTime(), event: "gtm.js" });

  const script = document.createElement("script");
  script.src = `https://www.googletagmanager.com/gtm.js?id=${gtmID}`;
  script.async = true;
  script.onerror = (error) => console.warn(`Unable to load gtm: ${error}`);
  document.head.appendChild(script);
};

/**
 * Top level wrapper initialising external providers and themes
 */
const App = () => {
  const { gtm } = config;
  gtmService(gtm.id);

  return (
    <AppInsightsContext.Provider value={aiReactPlugin}>
      <QueryClientProvider client={queryClient}>
        <IonApp>
          <Router>
            <ApplyPages />
          </Router>
        </IonApp>
      </QueryClientProvider>
    </AppInsightsContext.Provider>
  );
};

export default App;
