import { useMutation, useQuery } from "@tanstack/react-query";
import {
  LearnerProfileData,
  EducationInfoData,
  SubmitLearnerProfileResponse,
  FetchLearnerProfileResponse,
  FetchEducationProfileResponse,
} from "types/learner-profile";
import { apiGet, apiPost } from "./api-client";

const submitPersonalInfo = async (learnerProfileData: LearnerProfileData): Promise<SubmitLearnerProfileResponse> =>
  apiPost("/api/applynxuaggregator/self/personal-information", learnerProfileData);

const submitEducationInfo = async (educationInfoData: EducationInfoData): Promise<EducationInfoData> =>
  apiPost("/api/learner-profile/learners/self/education", educationInfoData);

const fetchLearnerProfile = async (): Promise<FetchLearnerProfileResponse> =>
  apiGet("/api/learner-profile/learners/self");

const fetchEducationProfile = async (): Promise<FetchEducationProfileResponse> =>
  apiGet("/api/learner-profile/learners/self/education");

const useSubmitPersonalInfo = (onSuccess: (resp: SubmitLearnerProfileResponse) => void) =>
  useMutation({
    mutationFn: (learnerProfileData: LearnerProfileData): Promise<SubmitLearnerProfileResponse> =>
      submitPersonalInfo(learnerProfileData),
    onSuccess,
  });

const useSubmitEducationInfo = (onSuccess: (resp: EducationInfoData) => void) =>
  useMutation({
    mutationFn: (educationInfoData: EducationInfoData): Promise<EducationInfoData> =>
      submitEducationInfo(educationInfoData),
    onSuccess,
  });

const useLearnerProfileInfo = (token: string) =>
  useQuery({
    queryKey: ["learnerProfile", token],
    queryFn: () => fetchLearnerProfile(),
    enabled: !!token,
    refetchOnWindowFocus: false,
  });

const useEducationProfileInfo = (token: string) =>
  useQuery({
    queryKey: ["educationProfile", token],
    queryFn: () => fetchEducationProfile(),
    enabled: !!token,
    refetchOnWindowFocus: false,
  });

export { useSubmitPersonalInfo, useSubmitEducationInfo, useLearnerProfileInfo, useEducationProfileInfo };
