import React, { useEffect, useState } from "react";
import { IonIcon, IonInput } from "@ionic/react";
import { checkmarkCircleOutline } from "ionicons/icons";
import { useForm, SubmitHandler, Controller } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

import { useValidateVerification, useEmailVerification } from "utils/hooks/authentication";
import { StorageKeys } from "constants/storage-keys";

import { NxuAlert, NxuPrimaryButton, NxuSecondaryButton } from "@nexford/nexford-ui-component-library";

import "./code-verification-form.scss";

// Code Form validation schema
const codeVerificationFormSchema = yup.object({
  Email: yup.string().trim().required(),
  Code: yup.string().trim().required(),
});

export interface CodeVerificationFormProps {
  emailSubmitted: boolean;
  storedEmail: string;
  emailVerified: boolean;
  setCodeVerified: (isTrue: boolean) => void;
  setTokenResponse: (token: string) => void;
  parentDisabledEvent?: boolean;
}

/**
 * Having submitted their email, applicant must verify the code they received
 */
const CodeVerificationForm = (props: CodeVerificationFormProps) => {
  const { storedEmail, emailVerified, emailSubmitted, setCodeVerified, setTokenResponse, parentDisabledEvent } = props;

  const [verificationErrorMsg, setVerificationErrorMsg] = useState<string>("");
  const [codeResendAvailable, setCodeResendAvailable] = useState<boolean>(false);

  const sendCodeToVerification = useValidateVerification((resp) => {
    setCodeVerified(true);
    sessionStorage.setItem(StorageKeys.APPLICATION_TOKEN, resp.Link);
    setTokenResponse(resp.Link);
  });

  const sendVerificationEmail = useEmailVerification();

  const isVerificationComplete = sendCodeToVerification.isSuccess || emailVerified;

  const newCodeVerificationForm = useForm({
    defaultValues: {
      Email: storedEmail || "",
      Code: "",
    },
    resolver: yupResolver(codeVerificationFormSchema),
  });
  const {
    reset,
    control,
    handleSubmit,
    setValue,
    formState: { isValid, errors },
  } = newCodeVerificationForm;

  const handleCodeVerificationSubmit: SubmitHandler<any> = async (formValues) => {
    // Function shouldn't be reachable without valid form values, but adding an escape just in case
    newCodeVerificationForm.setValue("Code", formValues.Code.trim(), { shouldValidate: true, shouldDirty: true });
    newCodeVerificationForm.setValue("Email", formValues.Email.trim(), { shouldValidate: true, shouldDirty: true });

    if (!isValid || sendCodeToVerification.isPending) return;
    sendCodeToVerification.mutate({
      Code: formValues.Code.trim(),
      Email: formValues.Email.trim(),
    });
  };

  const resendVerificationCode = async () => {
    // Function shouldn't be reachable without valid form values, but adding an escape just in case
    if (sendVerificationEmail.isPending) return;
    sendVerificationEmail.mutate({ Email: storedEmail });
  };

  const applyHotjarAttr = async (e: any) => {
    // Hotjar attr needs to be assigned to the inner input field of the IonInputs, as it doesn't apply recursively when applied directly to the IonInput tag
    // The form and input fields don't trigger any callbacks on mount, so we want to use the field's focus event as the trigger for setting the attr
    e.target.querySelector('input[aria-label="Verification code"]')?.setAttribute("data-hj-allow", "true");
  };

  useEffect(() => {
    if (sendVerificationEmail.isSuccess) {
      sendCodeToVerification.reset();
      sendVerificationEmail.reset();
      reset({ Code: "", Email: storedEmail });
      setCodeResendAvailable(false);
    }
  }, [setVerificationErrorMsg, sendCodeToVerification, sendVerificationEmail, reset, storedEmail]);

  useEffect(() => {
    if (sendCodeToVerification.isError) {
      const { error } = sendCodeToVerification;
      if (error.cause === 422 || error.cause === 400) {
        setCodeResendAvailable(true);
        setVerificationErrorMsg("Invalid or expired code was submitted");
      } else {
        setVerificationErrorMsg(
          `We were unable to process your verification code. Please refresh the page and try again`,
        );
      }
    }
    // @ts-ignore
  }, [sendCodeToVerification.isError, sendCodeToVerification.error]);

  useEffect(() => {
    if (storedEmail) {
      setValue("Email", storedEmail);
    }
  }, [setValue, storedEmail]);

  if (isVerificationComplete) {
    return (
      <div className="code-verification__complete">
        <IonIcon icon={checkmarkCircleOutline} />
        <span>Your email address has been verified</span>
      </div>
    );
  }

  if (emailSubmitted) {
    return (
      <div>
        <form
          className="code-verification-form"
          onSubmit={handleSubmit(handleCodeVerificationSubmit)}
          data-testid="code-verification-form"
        >
          <h4 className="code-verification-form__title">
            We've sent a verification code to your email. Please copy and paste it into the field below or hit the
            pencil icon to provide a different email.
          </h4>

          <Controller
            control={control}
            name="Code"
            render={({ field }) => (
              <IonInput
                value={field.value}
                data-testid="code-verification-input"
                fill="outline"
                disabled={sendCodeToVerification.isPending || parentDisabledEvent}
                onIonInput={field.onChange}
                onIonBlur={field.onBlur}
                onIonFocus={(e) => applyHotjarAttr(e)}
                type="text"
                placeholder="Verification code"
                aria-label="Verification code"
                errorText={errors.Code?.message}
                className={errors.Code ? "ion-touched ion-invalid" : ""}
              />
            )}
          />

          {sendCodeToVerification.isError && <NxuAlert fullWidth message={verificationErrorMsg} />}

          {codeResendAvailable && (
            <NxuSecondaryButton
              disabled={sendVerificationEmail.isPending || parentDisabledEvent}
              onClick={resendVerificationCode}
            >
              Resend verification code
            </NxuSecondaryButton>
          )}

          <NxuPrimaryButton
            type="submit"
            expand="block"
            disabled={sendCodeToVerification.isPending || sendCodeToVerification.isSuccess || parentDisabledEvent}
          >
            {sendCodeToVerification.isPending ? "Verification in progress" : "Verify"}
          </NxuPrimaryButton>
        </form>
      </div>
    );
  }

  return null;
};

export default CodeVerificationForm;
