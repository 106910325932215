export enum RegistrationStatus {
  Initialized = "Initialized",
  Submitted = "Submitted",
  Completed = "Completed",
  Rejected = "Rejected",
  Abandoned = "Abandoned",
}

export enum RequirementStatus {
  Pending = "Pending",
  Fulfilled = "Fulfilled",
}

export enum RequirementType {
  PersonalInfo = "PersonalInfo",
  EnglishProficiency = "EnglishProficiency",
  EducationInfo = "EducationInfo",
  UnofficialTranscript = "UnofficialTranscript",
  GovIdentity = "GovIdentity",
  PhotoIdentity = "PhotoIdentity",
  Agreement = "Agreement",
  TermsAndConditions = "TermsAndConditions",
  PreviousDegreeLevel = "PreviousDegreeLevel",
  TuitionFee = "TuitionFee",
  ApplicationFee = "ApplicationFee",
  AdmissionDecision = "AdmissionDecision",
}

export interface ProgramRegistrationPayload {
  productType: string;
  productCode: string;
}

export interface ProgramRegistrationResponse {
  productType: string;
  productCode: string;
  status: string;
  requirements: RegistrationRequirement[];
}

export interface AdmissionApplicationDataResponse {
  productCode: string;
  productType: string;
  status: string;
  decisionMade?: boolean;
}

export interface RegistrationData {
  productType: string;
  productCode: string;
  status: string;
  requirements: RegistrationRequirement[];
}

export interface RegistrationResponse {
  registrationDto: RegistrationData | null;
  applicationDto: AdmissionApplicationDataResponse | null;
}

export type RegistrationRequirement = {
  requirement: RequirementType;
  status: RequirementStatus;
};
