import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { IonButton, IonButtons, IonModal, IonHeader, IonToolbar, IonTitle, IonContent } from "@ionic/react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";

import { NxuAlert, NxuComponentLoading, NxuPrimaryButton } from "@nexford/nexford-ui-component-library";
import { NEXFORD_HOME_PROGRAMS } from "constants/external-routes";
import GetInTouch from "components/atom/get-in-touch";
import NxuSelect from "components/atom/nxu-select";
import { useAllProductsList, useProgramDetails } from "utils/hooks/admissions";
import { LocalRoutes } from "constants/routes";
import { ProductsListItem } from "types/admissions";
import { SelectItem } from "types/forms";

import "./products-list-modal.scss";

export interface ProductsListModalProps {
  isOpen: boolean;
  closeModal: () => void;
  noCurrentProduct?: boolean;
}

/**
 * Display an info modal for switching application product
 */
const ProductsListModal = (props: ProductsListModalProps) => {
  const { isOpen, closeModal, noCurrentProduct } = props;
  const navigate = useNavigate();

  const [tabIndex, setTabIndex] = useState(0);

  const [selectedProduct, setSelectedProduct] = useState<ProductsListItem>();
  const [degreesList, setDegreesList] = useState<SelectItem[]>([]);
  const [coursesList, setCoursesList] = useState<SelectItem[]>([]);
  const [certsList, setCertsList] = useState<SelectItem[]>([]);

  const { data: productsList, isLoading: productsListLoading, isError: productsListError } = useAllProductsList(isOpen);

  const { data: programDetails, isLoading: programDetailsLoading } = useProgramDetails(
    selectedProduct?.productType,
    selectedProduct?.productCode,
  );

  useEffect(() => {
    const degrees: SelectItem[] = [];
    const certs: SelectItem[] = [];
    const courses: SelectItem[] = [];

    if (productsList?.length) {
      productsList.forEach((item, index) => {
        if (item.productType === "Degree") degrees.push({ label: item.friendlyName, value: index });
        if (item.productType === "Certificate") certs.push({ label: item.friendlyName, value: index });
        if (item.productType === "Course") courses.push({ label: item.friendlyName, value: index });
      });

      setDegreesList(degrees);
      setCertsList(certs);
      setCoursesList(courses);
    }
  }, [productsList]);

  const handleClose = () => {
    closeModal();
    setSelectedProduct(undefined);
  };

  const selectProduct = (selectedIndex: number) => {
    const selection = productsList?.[selectedIndex];
    if (!selection) return;
    setSelectedProduct(selection);
  };

  const handleProgramSwitch = () => {
    if (!selectedProduct) return;
    navigate({
      pathname: LocalRoutes.APPLICATION,
      search: `type=${selectedProduct.productType}&product=${selectedProduct.productCode}`,
    });
    closeModal();
    setSelectedProduct(undefined);
  };

  return (
    <IonModal
      data-testid="product-selection-modal"
      isOpen={isOpen}
      onDidDismiss={handleClose}
      className="nxu-modal product-selection-modal"
    >
      <IonHeader className="product-selection-modal__header">
        <IonToolbar>
          <IonTitle data-testid="product-selection-modal-title">
            {noCurrentProduct ? "Choose Program" : "Change Program"}
          </IonTitle>
          <IonButtons slot="end">
            <IonButton fill="outline" onClick={handleClose}>
              Close
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>

      <IonContent className="product-selection-modal__content">
        <div className="product-selection-modal__content-inner">
          {productsListLoading && <NxuComponentLoading testId="product-selection-modal__loading" />}
          {productsListError && <NxuAlert message="There was an error on loading the list of programs available" />}
          {productsList && (
            <>
              <h3>
                {noCurrentProduct
                  ? "Find and select the program you want to start"
                  : "Find and select the program you want to switch to"}{" "}
              </h3>
              <Tabs
                className="product-selection-modal__tab-section"
                selectedIndex={tabIndex}
                onSelect={(index) => setTabIndex(index)}
              >
                <TabList data-testid="product-selection-modal__tablist">
                  {!!degreesList && <Tab>Degrees</Tab>}
                  {!!certsList && <Tab>Certificates</Tab>}
                  {!!coursesList && <Tab>Courses</Tab>}
                </TabList>

                {!!degreesList && (
                  <TabPanel>
                    <NxuSelect
                      selectedValue={undefined}
                      onChange={(option) => selectProduct(option.value)}
                      options={degreesList}
                      placeholder={"Select Degree"}
                      isDisabled={false}
                    />
                  </TabPanel>
                )}

                {!!certsList && (
                  <TabPanel>
                    <NxuSelect
                      selectedValue={undefined}
                      onChange={(option) => selectProduct(option.value)}
                      options={certsList}
                      placeholder={"Select Certificate"}
                      isDisabled={false}
                    />
                  </TabPanel>
                )}

                {!!coursesList && (
                  <TabPanel>
                    <NxuSelect
                      selectedValue={undefined}
                      onChange={(option) => selectProduct(option.value)}
                      options={coursesList}
                      placeholder={"Select Course"}
                      isDisabled={false}
                    />
                  </TabPanel>
                )}
              </Tabs>
            </>
          )}

          <div data-testid="product-selection-modal__selected" className="product-selection-modal__selected">
            {selectedProduct && (
              <>
                <p>
                  <strong>
                    {selectedProduct.productType !== "Degree" ? (
                      <>
                        {selectedProduct.productType} &#8226; {selectedProduct.productCode}
                      </>
                    ) : (
                      <>{selectedProduct.friendlyName}</>
                    )}
                  </strong>
                </p>
                {programDetailsLoading && <NxuComponentLoading />}
                {programDetails && <p>{programDetails.ProductDescription}</p>}
                <NxuPrimaryButton expand="block" onClick={handleProgramSwitch}>
                  Confirm Program Selection
                </NxuPrimaryButton>
              </>
            )}
          </div>
          <GetInTouch>
            <p>
              Not sure which to choose? <a href={NEXFORD_HOME_PROGRAMS}>Take a look at our Catalog</a> or get in touch
              and we'll help
            </p>
          </GetInTouch>
        </div>
      </IonContent>
    </IonModal>
  );
};

export default ProductsListModal;
