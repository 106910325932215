import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { Elements, useStripe } from "@stripe/react-stripe-js";
import cx from "classnames";
import { IonButton, IonIcon } from "@ionic/react";
import { checkmarkCircleOutline, radioButtonOffOutline } from "ionicons/icons";

import {
  AuthorizePaymentPayload,
  FlutterwaveCallback,
  FlywireCallback,
  PaymentPollingStatus,
  PaymentProviders,
  PaymentsTypeOptions,
  PaymentTypes,
  PPO,
  ProviderItem,
  ProviderTypes,
  SubmitPaymentPayload,
} from "types/payments";
import { Product } from "types/admissions";
import { HttpError } from "utils/errors/HttpError";
import {
  paymentsErrMsg,
  useAuthorizePayment,
  useCompletePaymentProvider,
  usePaymentSubscription,
  useStartPayment,
} from "utils/hooks/payments";
import { useStripeContext } from "utils/context/Stripe";
import {
  generateProviderSelectionButtonLabel,
  generateProvidersList,
  getPaymentErrorMessage,
  getProviderIcon,
  paymentRegistrationPayloadGenerator,
} from "utils/checkout-utils";

import {
  NxuAlert,
  NxuComponentLoading,
  NxuContentLoading,
  NxuPrimaryButton,
} from "@nexford/nexford-ui-component-library";
import CardPanel from "components/atom/card-panel";
import Flutterwave from "components/molecule/payment-provider/flutterwave";
import ExternalProviderModal from "components/molecule/payment-provider/external-provider-modal";
import Flywire from "components/molecule/payment-provider/flywire";
import StripePaymentForm from "components/molecule/payment-provider/stripe";

import "./payment-provider-options.scss";
import { OfferCoupons } from "constants/offers";
import PaypalForm from "../payment-provider/paypal";

const { FLYWIRE, FLUTTERWAVE, STRIPE, TRANSFER, PAYPAL, NONE } = PPO;

const Copy = {
  flywireErrMsg:
    "We were unable to complete the payment through Flywire. If this was in error, please try again or contact support.",
  flutterwaveErrMsg:
    "We were unable to complete the payment through Flutterwave. If this was in error, please try again or contact support.",
  paymentProgressErrMsg:
    "We were unable to proceed with your payment. Please contact support to confirm the status of the payment.",
  paymentAuthenticationErrorMessage:
    "Transaction declined due to authentication failure. Try again or use a different payment method.",
  paymentDefaultSuccessMessage:
    "Success! Your payment has been completed. Payments can take 24-48 hours to be reflected in your Nexford Wallet.",
};
export interface PaymentProviderProps {
  checkoutType: PaymentTypes;
  learnerId: string;
  email: string;
  country: string;
  programDetails?: Product;
  paymentProviders?: PaymentProviders;
  paymentProvidersError: Error | null;
  loadingProviders: boolean;
  startMonth?: number;
  couponCode: string | null;
  allowSkipPayment: boolean;
  paymentSubmitting: boolean;
  setPaymentSubmitting: (isSubmitting: boolean) => void;
  onSuccess: () => void;
}

const PaymentProviderOptions = ({
  checkoutType,
  learnerId,
  email,
  country,
  programDetails,
  paymentProviders,
  paymentProvidersError,
  loadingProviders,
  startMonth,
  couponCode,
  allowSkipPayment,
  paymentSubmitting,
  setPaymentSubmitting,
  onSuccess,
}: PaymentProviderProps) => {
  const currencies = paymentProviders?.currencies;
  const flutterwaveConfig = paymentProviders?.flutterWaveConfig;
  const flywireConfig = paymentProviders?.flywireConfig;
  const callbackId = paymentProviders?.callbackId;

  const stripe = useStripe();
  const [isApplicationFee] = useState<boolean>(checkoutType === PaymentsTypeOptions.ApplicationFee);
  const [currencyProvidersList, setCurrencyProvidersList] = useState<Array<ProviderItem>>();
  const [modalProvidersList, setModalProvidersList] = useState<Array<ProviderItem>>();

  const [currentProvider, setCurrentProvider] = useState<ProviderTypes | null>(null);
  const [currentProviderIndex, setCurrentProviderIndex] = useState<number | null>(null);
  const [currentAmount, setCurrentAmount] = useState<number>();

  const [paymentRegistrationError, setPaymentRegistrationError] = useState<boolean>(false);
  const [pollingIsInitiated, setPollingIsInitiated] = useState(false);
  const [pollingCounter, setPollingCounter] = useState<number>(0);
  const [paymentError, setPaymentError] = useState<string>();

  const [isExternalModalOpen, setIsExternalModalOpen] = useState(false);

  // Get admission status, will fetch when token is found in session store
  const {
    data: paymentPolling,
    isRefetching: isPaymentPollingRefetching,
    error: paymentPollingError,
    isSuccess: paymentPollingSuccess,
  } = usePaymentSubscription(currentProvider, callbackId, pollingIsInitiated);

  const startPayment = useStartPayment();
  const authorizePayment = useAuthorizePayment();
  const completePaymentProvider = useCompletePaymentProvider();

  useEffect(() => {
    if (currencies) {
      setCurrencyProvidersList(generateProvidersList(currencies));
    }
    if (couponCode && OfferCoupons.NxuNgn.test(couponCode)) {
      setModalProvidersList([]);
    } else if (!currencies?.find((x) => x.currencyCode === "NGN")) {
      setModalProvidersList([{ provider: TRANSFER }]);
    }
  }, [currencies, couponCode]);

  useEffect(() => {
    // Callback ID in the payment providers has updated, reset the current provider selection
    if (callbackId && currentProvider) {
      setCurrentProvider(null);
      setCurrentProviderIndex(null);
      setCurrentAmount(0);
    }
  }, [callbackId]);

  // When payment is initiated on Flywire or Flutterwave
  const registerPaymentStart = useCallback(
    (providerName: ProviderTypes, providerAmount: number) => {
      if (!programDetails || !callbackId || typeof startMonth !== "number") return;
      const paymentRegistrationPayload = paymentRegistrationPayloadGenerator(
        checkoutType,
        providerName,
        programDetails,
        callbackId,
        country,
        providerAmount,
        startMonth,
        couponCode,
      );

      if (!startPayment.isPending) {
        startPayment.mutate(paymentRegistrationPayload, {
          onSuccess() {},
          onError() {
            setPaymentRegistrationError(true);
          },
        });
      }
    },
    [startPayment, programDetails, callbackId, startMonth],
  );

  // update event when 3rd party payment providers returns a success response
  const paymentProviderCompletion = async (provider: ProviderTypes) => {
    const paymentSubmitPayload: SubmitPaymentPayload = {
      provider,
      paymentReference: callbackId || "",
      paymentType: checkoutType,
    };

    completePaymentProvider.mutate(paymentSubmitPayload, {
      onSuccess() {
        setPollingIsInitiated(true);
        setPaymentSubmitting(true);
      },
      onError(e) {
        if ((e as HttpError<undefined>).statusCode === 409) {
          setPollingIsInitiated(true);
          setPaymentSubmitting(true);
        } else {
          setPaymentError(Copy.paymentProgressErrMsg);
          setPaymentSubmitting(false);
        }
      },
    });
  };

  const handleSkipPayment = async () => {
    if (startPayment.isPending || !programDetails || !callbackId || typeof startMonth !== "number" || currentAmount)
      return;
    setPaymentSubmitting(true);

    const paymentRegistrationPayload = paymentRegistrationPayloadGenerator(
      checkoutType,
      NONE,
      programDetails,
      callbackId,
      country,
      0,
      startMonth,
      couponCode,
    );

    startPayment.mutate(paymentRegistrationPayload, {
      onSuccess() {
        setCurrentProvider(PPO.NONE);
        setPollingIsInitiated(true);
      },
      onError() {
        setPaymentRegistrationError(true);
        setPaymentSubmitting(false);
      },
    });
  };

  // When payment is initiated with Stripe
  const handleStripePayment = async (paymentId: string, paymentMethod: "Create" | "Confirm" = "Create") => {
    if (startPayment.isPending || !programDetails || !callbackId || !currentAmount || typeof startMonth !== "number")
      return;
    setPaymentSubmitting(true);

    if (paymentMethod === "Create") {
      const paymentRegistrationPayload = paymentRegistrationPayloadGenerator(
        checkoutType,
        PPO.STRIPE,
        programDetails,
        callbackId,
        country,
        currentAmount,
        startMonth,
        couponCode,
        { paymentMethodId: paymentId },
      );

      startPayment.mutate(paymentRegistrationPayload, {
        onSuccess() {
          paymentProviderCompletion(STRIPE);
        },
        onError() {
          setPaymentRegistrationError(true);
        },
      });
    }

    if (paymentMethod === "Confirm") {
      const payload: AuthorizePaymentPayload = {
        provider: PPO.STRIPE,
        amount: currentAmount,
        countryCode: country,
        externalReference: paymentId,
        paymentFrequency: 1,
        startMonth: startMonth || 0,
        paymentType: checkoutType,
        paymentReference: callbackId,
        couponCode,
      };

      authorizePayment.mutate(payload, {
        onSuccess() {
          paymentProviderCompletion(STRIPE);
        },
        onError() {
          setPaymentRegistrationError(true);
        },
      });
    }
  };

  // On progressing through the stripe payment, a confirmation event has been triggered by the response from the initial stripe payment event
  const handleStripePaymentConfirmation = async () => {
    setPollingIsInitiated(false);

    const paymentIntentSecret = paymentPolling?.paymentIntentResponse?.clientSecret;
    const intentActionResponse = await stripe?.handleCardAction(paymentIntentSecret || "");
    const paymentIntentId = intentActionResponse?.paymentIntent ? intentActionResponse.paymentIntent.id : null;

    if (!paymentIntentId) {
      setPaymentError(Copy.paymentAuthenticationErrorMessage);
      setPaymentSubmitting(false);
      return;
    }

    handleStripePayment(paymentIntentId, "Confirm");
  };

  const handleStripePaymentCreate = (inProgress: boolean) => {
    setPaymentError(undefined);
    // Before stripe progresses to the payment stage, it has a separate payment creation event that needs time to complete
    setPaymentSubmitting(inProgress);
  };

  const resetOfPaymentEvents = () => {
    setPollingIsInitiated(false);
    setPaymentError(undefined);
    setPaymentSubmitting(false);
  };

  // Allow the user to select the payment type they want to use
  const selectPaymentProvider = useCallback(
    (provider: ProviderItem, index: number) => {
      if (paymentSubmitting || startPayment.isPending || paymentPolling?.status === PaymentPollingStatus.PAID) return;
      resetOfPaymentEvents();
      setCurrentProvider(provider.provider);
      setCurrentProviderIndex(index);

      let providerAmount;
      if (provider.provider === "flywire") {
        providerAmount = flywireConfig.amount;
        setCurrentAmount(flywireConfig.amount);
      } else {
        providerAmount = provider.providerAmount;
        setCurrentAmount(provider.providerAmount);
      }

      switch (provider.provider) {
        case STRIPE:
          return;
        case TRANSFER: {
          setIsExternalModalOpen(true);
          return;
        }
        default: {
          registerPaymentStart(provider.provider, providerAmount);
        }
      }
    },
    [paymentSubmitting, startPayment, registerPaymentStart, flywireConfig],
  );

  // Add a ref with a scroll event to scroll down to the form once rendered
  const paymentWrapperRef = useRef<HTMLDivElement>(null);
  const scrollToElement = () => {
    setTimeout(() => {
      paymentWrapperRef.current?.scrollIntoView({ behavior: "smooth" });
    }, 100);
  };
  useEffect(() => {
    if (currentProvider === STRIPE || currentProvider === FLYWIRE || currentProvider === FLUTTERWAVE) scrollToElement();
  }, [currentProvider]);

  const generateProviderSelectionButton = useCallback(
    (p: ProviderItem, i: number) => (
      <IonButton
        fill="outline"
        className={cx("payment-provider__item", currentProvider === p.provider ? "selected" : "")}
        key={`payment-provider__item-${i}`}
        onClick={() => selectPaymentProvider(p, i)}
        disabled={paymentSubmitting}
      >
        <div className="logo">{getProviderIcon(p.provider)}</div>
        <div className="text">{generateProviderSelectionButtonLabel(p.provider, p.currencyCode)}</div>
        <div className="check-icon">
          {currentProvider === p.provider ? (
            <IonIcon size="default" icon={checkmarkCircleOutline} />
          ) : (
            <IonIcon icon={radioButtonOffOutline} />
          )}
        </div>
      </IonButton>
    ),
    [currentProvider, paymentSubmitting, selectPaymentProvider],
  );

  const closePaymentModal = () => {
    setIsExternalModalOpen(false);
    setCurrentProvider(null);
    setCurrentProviderIndex(null);
  };

  const onSubmitFlywire = async (data: FlywireCallback) => {
    setPaymentError(undefined);
    if (data.Status === "success") {
      await paymentProviderCompletion(FLYWIRE);
    } else if (data.Status === "error") {
      setPaymentError(Copy.flywireErrMsg);
      setPaymentSubmitting(false);
    }
  };

  const onSubmitFlutterwave = async (data: FlutterwaveCallback) => {
    setPaymentError(undefined);
    if (data.status === "successful" || data.status === "completed") {
      await paymentProviderCompletion(FLUTTERWAVE);
    } else {
      setPaymentError(Copy.flutterwaveErrMsg);
      setPaymentSubmitting(false);
    }
  };

  const onCompletePaypal = async () => {
    setPaymentError(undefined);
    setPollingIsInitiated(true);
    setPaymentSubmitting(true);
  };

  // Listen out for the payment completion polling, and handle any response
  useEffect(() => {
    if (paymentPollingSuccess && paymentPolling?.status !== PaymentPollingStatus.INITIATED) {
      if (paymentPolling.status === PaymentPollingStatus.PAID) {
        onSuccess();
      } else if (paymentPolling.status === PaymentPollingStatus.REQUIRES_AUTH) {
        handleStripePaymentConfirmation();
      } else if (
        paymentPolling.status === PaymentPollingStatus.CANCELED ||
        paymentPolling.status === PaymentPollingStatus.FAILED
      ) {
        if (pollingIsInitiated) {
          setPaymentError(
            paymentPolling.status === PaymentPollingStatus.FAILED
              ? getPaymentErrorMessage(paymentPolling.substatus)
              : paymentsErrMsg.cancelPaymentPolling,
          );
        }
        setPaymentSubmitting(false);
        setPollingIsInitiated(false);
      }
    } else if (paymentPollingError) {
      setPollingIsInitiated(false);
      setPaymentError(paymentPollingError.message);
    }
  }, [paymentPollingSuccess, paymentPollingError, paymentPolling]);

  useEffect(() => {
    if (isPaymentPollingRefetching && pollingIsInitiated) {
      setPollingCounter(pollingCounter + 1);
    }
  }, [isPaymentPollingRefetching, pollingIsInitiated]);

  useEffect(() => {
    if (loadingProviders) setPaymentError(undefined);
  }, [loadingProviders]);

  // Create a payment button for Flutterwave and Flywire
  const generatePaymentButton = () => {
    if (!currencies || !currentProvider || currentProviderIndex === null || (currentProvider !== STRIPE && !callbackId))
      return null;

    if (currentProvider === FLYWIRE && flywireConfig && startPayment.isSuccess) {
      return (
        <CardPanel className="payment-provider__action">
          <Flywire
            onCancel={() => setPaymentSubmitting(false)}
            onSubmit={onSubmitFlywire}
            submitting={paymentSubmitting || pollingIsInitiated}
            config={flywireConfig}
            callbackId={callbackId || ""}
          />
        </CardPanel>
      );
    }

    if (currentProvider.toLowerCase() === FLUTTERWAVE && flutterwaveConfig && startPayment.isSuccess) {
      return (
        <CardPanel className="payment-provider__action">
          <Flutterwave
            submitting={paymentSubmitting || pollingIsInitiated}
            onCancel={() => setPaymentSubmitting(false)}
            onSubmit={onSubmitFlutterwave}
            flutterwaveConfig={flutterwaveConfig}
            callbackId={callbackId || ""}
            currencyProvider={currencies[currentProviderIndex]}
            payee={{
              learnerId,
              email,
            }}
            reference={isApplicationFee ? "APP" : "SUB"}
            initiator="tuitionFee"
            couponCode={couponCode}
          />
        </CardPanel>
      );
    }

    if (
      currentProvider === PAYPAL &&
      startPayment.isSuccess &&
      currencyProvidersList &&
      currencyProvidersList[currentProviderIndex]
    ) {
      const matchedCurrency = currencies.find((item) => item.provider === PPO.PAYPAL);
      if (!matchedCurrency) return null;

      return (
        <CardPanel className="payment-provider__action">
          <PaypalForm
            onSubmit={onCompletePaypal}
            onCancel={() => setPaymentSubmitting(false)}
            callbackId={callbackId || ""}
            paypalProvider={matchedCurrency}
            couponCode={couponCode}
            submitting={paymentSubmitting || pollingIsInitiated}
          />
        </CardPanel>
      );
    }

    if (
      (currentProvider === FLYWIRE || currentProvider === FLUTTERWAVE || currentProvider === PAYPAL) &&
      startPayment.isPending
    ) {
      return (
        <CardPanel className="payment-provider__action">
          <NxuComponentLoading />
        </CardPanel>
      );
    }

    if (currentProvider === STRIPE) {
      return (
        <CardPanel className="payment-provider__action">
          <StripePaymentForm
            onPaymentMethodCreated={handleStripePayment}
            handleStripePaymentCreate={handleStripePaymentCreate}
            submitting={paymentSubmitting}
            fullReset={resetOfPaymentEvents}
            paymentError={paymentError}
            pollingDelay={pollingIsInitiated && pollingCounter > 100}
          />
        </CardPanel>
      );
    }

    return null;
  };

  // Memoise the list of payment option buttons generated from the providers list
  const generateProviderSelectionButtons = useMemo(() => {
    // Providers loaded, but no payment options available
    if (paymentProvidersError || !currencyProvidersList?.length) return null;

    return (
      <CardPanel className="payment-provider__list" testId="payment-provider-list">
        <h3>Recommended</h3>
        {currencyProvidersList.map((p, i) => generateProviderSelectionButton(p, i))}
        {modalProvidersList?.length ? <h3>Other</h3> : ""}
        {modalProvidersList?.map((p, i) => generateProviderSelectionButton(p, currencyProvidersList.length + i))}
      </CardPanel>
    );
  }, [currencyProvidersList, generateProviderSelectionButton, modalProvidersList, paymentProvidersError]);

  if (loadingProviders) return <NxuContentLoading mode="dark" />;

  if (allowSkipPayment) {
    return (
      <CardPanel className="payment-provider__wrapper payment-provider__wrapper--skip" testId="payment-provider-skip">
        <p>
          With the applied coupon code, you have nothing to pay at this time. <br /> Please click the button below to
          continue on with your application
        </p>
        {paymentRegistrationError && <NxuAlert message={paymentsErrMsg.skipRegistration} />}
        <NxuPrimaryButton
          onClick={handleSkipPayment}
          type="submit"
          expand="block"
          className="stripe-card__action"
          disabled={paymentSubmitting}
        >
          {paymentSubmitting ? "Processing coupon code" : "Complete Checkout"}
        </NxuPrimaryButton>
      </CardPanel>
    );
  }

  return (
    <div className="payment-provider__wrapper" data-testid="payment-provider-list">
      {paymentProvidersError && <NxuAlert message={paymentProvidersError?.message || paymentsErrMsg.fetchProviders} />}
      {paymentRegistrationError && <NxuAlert message={paymentsErrMsg.startRegistration} />}
      {pollingIsInitiated && pollingCounter > 100 && (
        <NxuAlert
          type="progress"
          message="Your payment is in progress. When it is confirmed, we'll email you with everything you need to complete
              your application. This can take up to 3 days so please keep an eye on your inbox! If you have any questions on the payment status, please contact us."
        />
      )}
      {generateProviderSelectionButtons}
      {paymentError && currentProvider !== PPO.STRIPE && <NxuAlert message={paymentError} />}
      <div ref={paymentWrapperRef}>{generatePaymentButton()}</div>
      <ExternalProviderModal
        learnerId={learnerId}
        type={currentProvider as string}
        isOpen={isExternalModalOpen}
        closeModal={closePaymentModal}
      />
    </div>
  );
};

const PaymentProviderOptionsWithStripe = (props: PaymentProviderProps) => {
  const { stripeApiKey, loading, stripeLoadingError } = useStripeContext();
  const stripePromise = useMemo(() => {
    if (stripeApiKey) {
      return loadStripe(stripeApiKey);
    }
    return null;
  }, [stripeApiKey]);

  if (loading) {
    return <NxuContentLoading mode="dark" />;
  }

  if (stripeLoadingError || !stripeApiKey) {
    return (
      <NxuAlert
        type="error"
        message={`There was an error in loading our payment processor, please refresh the page to try again. ${
          stripeLoadingError ? `Error: ${stripeLoadingError}` : ""
        }`}
      />
    );
  }

  return (
    <Elements stripe={stripePromise}>
      <PaymentProviderOptions {...props} />
    </Elements>
  );
};

export default PaymentProviderOptionsWithStripe;
