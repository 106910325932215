import React from "react";

import { useNationalities } from "utils/hooks/admissions";
import { SelectItem } from "types/forms";

import { NxuComponentLoading } from "@nexford/nexford-ui-component-library";
import ApplicationForm from "components/molecule/application-form";

import "./application.scss";
import { useRegistrationContext } from "utils/context/registration";
import { RequirementType } from "types/registrations";

/**
 * Program Application module handling the rendering of the different application form tye
 */

const Application = () => {
  const context = useRegistrationContext();

  const { programDetails, learnerProfileData } = context;

  const { data: nationalitiesList, isLoading: nationalitiesListLoading } = useNationalities(context.authorised);

  const currentYear = new Date().getFullYear() - 11;
  const yearsList: Array<SelectItem> = Array.from({ length: 100 }, (_, index) => ({
    label: currentYear - index,
    value: currentYear - index,
  }));

  if (nationalitiesListLoading) {
    return <NxuComponentLoading />;
  }

  return (
    <ApplicationForm
      email={context.email!}
      yearsList={yearsList}
      nationalitiesList={nationalitiesList}
      programDetails={programDetails}
      existingApplicantInfo={learnerProfileData}
      onSuccess={(resp) =>
        context.CompleteRequirements(
          () => Promise.resolve(context.SetLearnerProfileData(resp)),
          [RequirementType.PersonalInfo, RequirementType.EnglishProficiency, RequirementType.TermsAndConditions],
        )
      }
    />
  );
};

export default Application;
