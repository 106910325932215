import { useMutation, useQuery } from "@tanstack/react-query";
import { ProgramRegistrationPayload, ProgramRegistrationResponse, RegistrationResponse } from "types/registrations";
import { apiGet, apiPost } from "./api-client";

const fetchRegistrations = async (
  productType: string | null,
  productCode: string | null,
): Promise<RegistrationResponse> =>
  apiGet(
    `/api/applynxuaggregator/self/registrations/latest?productType=${productType || ""}&productCode=${
      productCode || ""
    }`,
  );

const useRegistration = (
  token: string,
  productType: string | null,
  productCode: string | null,
  refetchMatch: (data?: RegistrationResponse) => boolean,
) =>
  useQuery({
    queryKey: ["registration", token, productType, productCode],
    queryFn: (): Promise<RegistrationResponse> => fetchRegistrations(productType, productCode),
    enabled: !!token,
    refetchOnWindowFocus: false,
    refetchInterval: (query) => {
      if (query.state.dataUpdateCount > 60) return false;
      return !refetchMatch(query.state.data) && 3000;
    },
  });

const useStartRegistration = (onSuccess?: (resp: ProgramRegistrationResponse) => void) =>
  useMutation({
    mutationFn: (payload: ProgramRegistrationPayload): Promise<ProgramRegistrationResponse> =>
      apiPost("/api/applynxuaggregator/self/register", payload),
    onSuccess,
  });

export { useRegistration, useStartRegistration };
