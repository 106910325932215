import { useMutation } from "@tanstack/react-query";
import { SendVerificationCodeResponse, ValidateVerificationCodeResponse } from "types/authentication";
import { apiPost } from "./api-client";

const useEmailVerification = (onSuccess?: (response: SendVerificationCodeResponse) => void) =>
  useMutation({
    mutationFn: (payload: { Email: string }): Promise<SendVerificationCodeResponse> =>
      apiPost("/api/auth/send-verification-code", payload),
    onSuccess,
  });

const useValidateVerification = (onSuccess?: (response: ValidateVerificationCodeResponse) => void) =>
  useMutation({
    mutationFn: (payload: { Email: string; Code: string }): Promise<ValidateVerificationCodeResponse> =>
      apiPost("/api/auth/validate-verification-code", payload),
    onSuccess,
  });

export { useEmailVerification, useValidateVerification };
