import { SeverityLevel } from "@microsoft/applicationinsights-web";
import { QueryClient } from "@tanstack/react-query";
import { appInsights } from "utils/app-insights-telemetry";
import { HttpError } from "utils/errors/HttpError";

export const queryClient = new QueryClient();

const getJsonOrDefault = async (r: Response) => {
  try {
    return await r.json();
  } catch {
    return {};
  }
};
// Standardise the get request into a single function
export const apiGet = async (route: string, customErrorMessage?: string, header?: any) => {
  try {
    const request = await fetch(route, {
      headers: header || {},
    });
    // If a request has no content, return an empty object
    if (request.status === 204) return {};
    if (!request.ok) throw await request;
    return await getJsonOrDefault(request);
  } catch (e) {
    appInsights.trackException({
      exception: e as Error,
      severityLevel: SeverityLevel.Error,
    });

    const error = e as Response;
    const errorJson = await getJsonOrDefault(error);

    throw new HttpError(customErrorMessage || `${error.status} on fetch from ${route}`, error.status, errorJson);
  }
};

export const apiPost = async (route: string, payload: { [key: string]: any }, customErrorMessage?: string) => {
  try {
    const request = await fetch(route, {
      method: "post",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    });
    if (!request.ok) throw await request;
    // If a request has no content, return an empty object
    if (request.status === 204) return {};
    return await getJsonOrDefault(request);
  } catch (e) {
    appInsights.trackException({
      exception: e as Error,
      severityLevel: SeverityLevel.Error,
    });

    const error = e as Response;
    const errorJson = await getJsonOrDefault(error);
    throw new HttpError(customErrorMessage || `${error.status} on post to ${route}`, error.status, errorJson);
  }
};

export const apiPut = async (route: string, payload: { [key: string]: any }, customErrorMessage?: string) => {
  try {
    const request = await fetch(route, {
      method: "put",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    });
    if (!request.ok) throw await request;
    // If a request has no content, return an empty object
    if (request.status === 204) return {};
    return await getJsonOrDefault(request);
  } catch (e) {
    appInsights.trackException({
      exception: e as Error,
      severityLevel: SeverityLevel.Error,
    });

    const error = e as Response;
    const errorJson = await getJsonOrDefault(error);
    throw new HttpError(customErrorMessage || `${error.status} on put to ${route}`, error.status, errorJson);
  }
};

export const apiPostFile = async (route: string, file: File, customErrorMessage?: string) => {
  try {
    const request = await fetch(route, {
      method: "post",
      headers: {},
      body: file,
    });
    if (!request.ok) throw await request;
    // If a request has no content, return an empty object
    if (request.status === 204) return {};
    return await getJsonOrDefault(request);
  } catch (e) {
    console.warn(e);
    appInsights.trackException({ exception: e as Error, severityLevel: SeverityLevel.Error });
    const error = e as Response;
    const errorJson = await getJsonOrDefault(error);
    throw new HttpError(customErrorMessage || `${error.status} on post to ${route}`, error.status, errorJson);
  }
};
