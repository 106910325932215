import React, { useCallback, useEffect, useState } from "react";
import { IonButton, IonIcon, IonInput, IonPopover, IonSpinner } from "@ionic/react";
import { checkmarkCircleOutline, informationCircleOutline } from "ionicons/icons";

import { useForm, SubmitHandler, Controller } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

import { NxuAlert, NxuComponentLoading } from "@nexford/nexford-ui-component-library";

import { useRegistrationContext } from "utils/context/registration";
import { PaymentEstimate, PaymentTypes } from "types/payments";
import { NEXFORD_MAIL_SUCCESS } from "constants/external-routes";
import { FetchIdentityProfileResponse } from "types/learner-profile";
import { useAgreementAcceptance, useAgreementDocument } from "utils/hooks/payments";

import DocumentViewer from "components/atom/document-viewer";
import CardPanel from "components/atom/card-panel";

import "./enrollment-agreement.scss";

export interface EnrollmentAgreementProps {
  checkoutType: PaymentTypes;
  identity?: FetchIdentityProfileResponse;
  estimates: PaymentEstimate | null;
  startDate?: string;
  couponCode: string | null;
  enableNext: boolean;
  setIsAgreementLoaded: React.Dispatch<React.SetStateAction<boolean>>;
  isAgreementSigned: boolean;
  setIsAgreementSigned: React.Dispatch<React.SetStateAction<boolean>>;
}

// Accept Form validation schema
const schema = yup.object({
  prepopulatedApplicantName: yup.string().required(),
  applicantName: yup
    .string()
    .required()
    .test(
      "names-match",
      "The name you have signed with must match the name we currently have in our records",
      (value, testContext) => {
        const prePop = testContext.parent.prepopulatedApplicantName;
        return value?.replace(/\s/g, "") === prePop.replace(/\s/g, "");
      },
    ),
});

interface AcceptFormInputs {
  applicantName: string;
  prepopulatedApplicantName: string;
}

/**
 * ApplyNXU Application Checkout
 */
export const EnrollmentAgreement = ({
  startDate,
  identity,
  estimates,
  couponCode,
  enableNext,
  setIsAgreementLoaded,
  isAgreementSigned,
  setIsAgreementSigned,
}: EnrollmentAgreementProps) => {
  const { registrationData, learnerProfileData } = useRegistrationContext();

  const getAgreementDocument = useAgreementDocument();
  const signEnrollmentAgreement = useAgreementAcceptance();

  const [agreementHtml, setAgreementHtml] = useState<any>();
  const [agreementDocError, setAgreementDocError] = useState<boolean>(false);

  const [submitting, setSubmitting] = useState(false);
  const [submissionError, setSubmissionError] = useState<string | null>();

  const {
    reset,
    control,
    handleSubmit,
    setValue,
    trigger,
    formState: { errors, isValid },
  } = useForm({
    defaultValues: {
      applicantName: "",
      prepopulatedApplicantName: identity?.officialName || "",
    },
    resolver: yupResolver(schema),
  });

  const resetEnrollmentAgreement = useCallback(async () => {
    if (agreementHtml || isAgreementSigned) {
      setAgreementHtml(undefined);
      setAgreementDocError(false);
      setSubmitting(false);
      setSubmissionError(null);
      setIsAgreementLoaded(false);
      setIsAgreementSigned(false);
      reset();
    }
  }, [agreementHtml, isAgreementSigned, reset, setIsAgreementSigned]);

  useEffect(() => {
    resetEnrollmentAgreement();
  }, [startDate, estimates, couponCode]);

  const continueEnrollment = () => {
    if (!registrationData || !learnerProfileData || !identity || !startDate || !estimates) return;

    const getAgreementDocumentPayload = {
      productCode: registrationData.productCode,
      productType: registrationData.productType,
      data: {
        email: learnerProfileData.Email,
        officialName: identity.officialName,
        city: learnerProfileData.City,
        country: learnerProfileData.Country,
        startDate,
        paymentFrequency: 1,
        subtotal: estimates.Subtotal,
        total: estimates.Total,
        couponCode,
      },
    };

    if (!getAgreementDocument.isPending) {
      getAgreementDocument.mutate(getAgreementDocumentPayload, {
        onSuccess(res) {
          setAgreementHtml(res.AgreementHtmlContent);
          setIsAgreementLoaded(true);
        },
        onError() {
          setAgreementDocError(true);
        },
      });
    }
  };

  const acceptEnrollmentAgreement = async (officialName: string) => {
    if (!registrationData || !startDate || signEnrollmentAgreement.isPending) return;

    setSubmitting(true);
    setSubmissionError(null);

    const signAgreementDocPayload = {
      productCode: registrationData.productCode,
      productType: registrationData.productType,
      data: {
        startDate,
        officialName,
      },
    };

    signEnrollmentAgreement.mutate(signAgreementDocPayload, {
      onSuccess() {
        setIsAgreementSigned(true);
        setSubmitting(false);
      },
      onError() {
        setSubmissionError("Failed to sign agreement");
        setSubmitting(false);
      },
    });
  };

  const handleSave: SubmitHandler<AcceptFormInputs> = async (formValues) => {
    await acceptEnrollmentAgreement(formValues.applicantName);
  };

  if (getAgreementDocument.isPending) {
    return (
      <CardPanel className="checkout-page__enrollment-agreement" testId="checkout-page__enrollment-agreement__loading">
        <NxuComponentLoading />
      </CardPanel>
    );
  }
  if (agreementDocError) {
    return (
      <CardPanel className="checkout-page__enrollment-agreement" testId="checkout-page__enrollment-agreement__error">
        <NxuAlert
          message={
            "There was a problem loading the enrollment agreement, please refresh the page. If the problem persists please contact support"
          }
        />
      </CardPanel>
    );
  }

  if (!agreementHtml) {
    return (
      <CardPanel
        centre
        className="checkout-page__enrollment-agreement"
        testId="checkout-page__enrollment-agreement__cta"
      >
        <IonButton
          data-testid="checkout-page__enrollment-agreement__continue_enrollment"
          disabled={!enableNext}
          onClick={continueEnrollment}
        >
          Next
        </IonButton>
      </CardPanel>
    );
  }

  if (isAgreementSigned) {
    return (
      <CardPanel
        className="checkout-page__enrollment-agreement checkout-page__enrollment-agreement--success"
        testId="checkout-page__enrollment-agreement-signed"
      >
        <p>
          <IonIcon icon={checkmarkCircleOutline} size="small" />
          Agreement signed. Choose how to pay your tuition
        </p>
      </CardPanel>
    );
  }

  return (
    <CardPanel className="checkout-page__enrollment-agreement" testId="checkout-page__enrollment-agreement" centre>
      <div className="agreement__form-title">
        <h2>Review and sign your enrollment agreement</h2>
        <IonButton id="enrollment-tooltip-trigger" aria-label="Open agreement tooltip" size="small" shape="round">
          <IonIcon slot="icon-only" icon={informationCircleOutline} />
        </IonButton>
        <IonPopover
          className="enrollment-tooltip"
          trigger="enrollment-tooltip-trigger"
          triggerAction="hover"
          side="bottom"
          alignment="start"
        >
          <p>
            Please, sign by typing your full legal name exactly as it is displayed. If this is not your full legal name,
            as displayed on your government-ID, please contact{" "}
            <a href="mailto: admissions@nexford.org">{NEXFORD_MAIL_SUCCESS}</a>
          </p>
        </IonPopover>
      </div>

      <DocumentViewer frameTitle="Agreement Document" file={agreementHtml} />

      <form className="agreement__form" onSubmit={handleSubmit(handleSave)} data-testid="agreement-form">
        <div className="agreement__form-fields">
          <div>
            <Controller
              control={control}
              name="prepopulatedApplicantName"
              render={({ field }) => (
                <IonInput
                  {...field}
                  fill="outline"
                  type="text"
                  readonly
                  aria-label="Sign by typing your full legal name*"
                  placeholder="Sign by typing your full legal name*"
                  data-testid="prepopulated-applicant-signature"
                />
              )}
            />
            <Controller
              control={control}
              name="applicantName"
              render={({ field }) => (
                <IonInput
                  onIonChange={field.onChange}
                  onIonBlur={field.onBlur}
                  onIonInput={(e) => {
                    setValue("applicantName", e.target.value?.toString() ?? "");
                    trigger("applicantName");
                  }}
                  fill="outline"
                  type="text"
                  data-testid="applicant-signature"
                  placeholder="Sign by typing your full legal name*"
                  errorText={errors.applicantName?.message?.replace("applicantName", "Applicant name")}
                  className={errors.applicantName ? "ion-touched ion-invalid" : ""}
                />
              )}
            />
          </div>
        </div>

        {!!submissionError && <NxuAlert message={submissionError} fullWidth={false} />}

        <div className="agreement__form-toolbar">
          <IonButton
            size="large"
            disabled={!isValid || submitting}
            type="submit"
            data-testid="checkout-page__enrollment-agreement__save-button"
          >
            {submitting ? <IonSpinner /> : <>Sign</>}
          </IonButton>
        </div>
      </form>
    </CardPanel>
  );
};

export default EnrollmentAgreement;
