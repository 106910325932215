import { useQuery } from "@tanstack/react-query";
import { Product, ProductDetails, ProductsListItem } from "types/admissions";
import { SelectItem } from "types/forms";
import { apiGet } from "./api-client";

export const admissionErrMsg = {
  fetchAdmissionStatus:
    "Uh-oh! There was an error on getting your application details. Please refresh and try again or contact support.",
};

const fetchProgramDetails = async (productType?: string, productCode?: string): Promise<Product> =>
  apiGet(`/api/requirements/versions/latest/products/${productType}/${productCode}`);

const useProgramDetails = (productType?: string | null, productCode?: string | null) =>
  useQuery({
    queryKey: ["program-details-request", productType, productCode],
    queryFn: () => fetchProgramDetails(productType || "", productCode || ""),
    throwOnError: false,
    enabled: !!productType && !!productCode,
    staleTime: Infinity,
  });

const fetchFullProgramDetails = async (productCode?: string): Promise<ProductDetails> =>
  apiGet(`/api/public/applynxuaggregator/course/${productCode}/info`);

const useFullProgramDetails = (isCourse?: boolean, productCode?: string | null) =>
  useQuery({
    queryKey: ["full-program-details-request", productCode],
    queryFn: () => fetchFullProgramDetails(productCode || ""),
    throwOnError: false,
    enabled: !!isCourse && !!productCode,
    staleTime: Infinity,
  });

const fetchAllProductsList = async (): Promise<Array<ProductsListItem>> =>
  apiGet(`/api/public/applynxuaggregator/catalog`);

const useAllProductsList = (enabled: boolean) =>
  useQuery({
    queryKey: ["all-products-request"],
    queryFn: fetchAllProductsList,
    throwOnError: false,
    enabled,
    staleTime: Infinity,
  });

const fetchNationalities = async (): Promise<Array<SelectItem>> => {
  const response = apiGet("/api/location/nationalities").then((resp) =>
    /* eslint-disable no-nested-ternary */
    resp.sort((a: { label: string; value: string }, b: { label: string; value: string }) =>
      a.label < b.label ? -1 : a.label > b.label ? 1 : 0,
    ),
  );

  return response;
};

const useNationalities = (enabled: boolean) =>
  useQuery({
    queryKey: ["nationalities-request"],
    queryFn: fetchNationalities,
    throwOnError: false,
    staleTime: Infinity,
    enabled,
  });

const fetchProgramStartDates = async (): Promise<Array<string>> =>
  apiGet("/api/admission/startDate-options/", admissionErrMsg.fetchAdmissionStatus);

const useProgramStartDates = (token: string | null) =>
  useQuery({
    queryKey: ["program-admission-start-dates"],
    queryFn: fetchProgramStartDates,
    throwOnError: false,
    enabled: !!token,
    refetchOnWindowFocus: false,
  });

export { useProgramDetails, useFullProgramDetails, useAllProductsList, useNationalities, useProgramStartDates };
