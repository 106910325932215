// Get config constants from the env file
export default {
  gtm: {
    id: process.env.REACT_APP_GTM_ID || "",
  },
  googleMaps: {
    id: process.env.REACT_APP_GOOGLE_MAPS_ID || "",
  },
  insights: {
    instrumentationKey: process.env.REACT_APP_INSIGHTS_INSTRUMENTATION_KEY || "",
  },
  paypal: {
    clientId: process.env.REACT_APP_PAYPAL_CLIENT_ID || "",
  }
};
