import React, { useState } from "react";
import EmailVerificationForm from "components/molecule/email-verification-form";
import CodeVerificationForm from "components/molecule/code-verification-form";
import { StorageKeys } from "constants/storage-keys";

import "./empty-verification-form.scss";

export interface EmptyVerificationFormProps {
  email?: string | null;
  token?: string | null;
  onTokenRetrieval?: (token: string, email: string) => void;
  onLearnerIdRetrieval?: (learnerId: string) => void;
  pageResetEvent?: () => void;
}

/**
 * Applicant has landed on ApplyNXU with no verification token and no product type & code
 * Check for an existing application and redirect them to the relevant page
 */
const EmptyVerificationForm = (props: EmptyVerificationFormProps) => {
  const { onTokenRetrieval, email, token, pageResetEvent: parentPageResetEvent, onLearnerIdRetrieval } = props;
  const [emailSubmitted, setEmailSubmitted] = useState<boolean>(!!email);
  const [emailToVerify, setEmailToVerify] = useState<string>(email || "");
  const [codeVerified, setCodeVerified] = useState<boolean>(!!token);

  const pageResetEvent = () => {
    // Reset the page state when applicant wants to edit their email
    setCodeVerified(false);
    sessionStorage.setItem(StorageKeys.APPLICATION_TOKEN, "");
    if (parentPageResetEvent) {
      parentPageResetEvent();
    }
  };

  return (
    <div className="empty-verification-page__returning-applicant">
      <EmailVerificationForm
        setEmailToVerify={setEmailToVerify}
        setEmailSubmitted={setEmailSubmitted}
        storedEmail={emailToVerify}
        emailSubmitted={emailSubmitted}
        parentResetEvent={pageResetEvent}
        setLearnerId={(learnerId) => {
          if (onLearnerIdRetrieval) {
            onLearnerIdRetrieval(learnerId);
          }
        }}
      />
      {emailSubmitted && (
        <CodeVerificationForm
          setCodeVerified={setCodeVerified}
          setTokenResponse={(t) => {
            if (onTokenRetrieval) {
              onTokenRetrieval(t, emailToVerify);
            }
          }}
          storedEmail={emailToVerify}
          emailSubmitted={emailSubmitted}
          emailVerified={codeVerified}
        />
      )}
    </div>
  );
};

export default EmptyVerificationForm;
